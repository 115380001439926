import React, { useState, useEffect } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import blobfish from './assets/blobfish.png';

const GlobalStyle = createGlobalStyle`
  body {
    cursor: url(${blobfish}) 16 16, auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const App = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = '0x1234567890123456789012345678901234567890';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        {[...Array(40)].map((_, index) => (
          <FloatingBlobfish key={index} src={blobfish} />
        ))}
        <CenterContent>
          <BlobfishImage src={blobfish} alt="Blobfish" />
          <Title>Blobfish</Title>
        </CenterContent>
        <RandomPositionedText top="15%" left="10%">
          <Subtitle>The most memeable animal on the blockchain</Subtitle>
        </RandomPositionedText>
        <RandomPositionedText bottom="20%" right="5%">
          <ContractAddress onClick={copyToClipboard}>
            {contractAddress}
            {copied && <CopiedText>Copied!</CopiedText>}
          </ContractAddress>
        </RandomPositionedText>
        <ButtonContainer>
          <RandomPositionedButton top="70%" left="20%">
            <StyledButton href="https://t.me/blobfishcoin" target="_blank" rel="noopener noreferrer">
              Frens
            </StyledButton>
          </RandomPositionedButton>
          <RandomPositionedButton top="30%" right="10%">
            <StyledButton href="https://www.dextools.io/app/en/ether/pair-explorer/0x1234567890123456789012345678901234567890" target="_blank" rel="noopener noreferrer">
              Number Go Up
            </StyledButton>
          </RandomPositionedButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const floatAnimation = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(100px, 100px) rotate(90deg); }
  50% { transform: translate(200px, 0) rotate(180deg); }
  75% { transform: translate(100px, -100px) rotate(270deg); }
  100% { transform: translate(0, 0) rotate(360deg); }
`;

const Container = styled.div`
  background: linear-gradient(
    90deg,
    #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080
  );
  background-size: 1200% 1200%;
  animation: ${rainbowAnimation} 20s linear infinite;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
`;

const BlobfishImage = styled.img`
  width: 80vw;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-family: 'Comic Sans MS', cursive;
  font-size: 48px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
`;

const Subtitle = styled.h2`
  font-family: 'Comic Sans MS', cursive;
  font-size: 24px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 10px 0;
`;

const ContractAddress = styled.div`
  font-family: 'Comic Sans MS', cursive;
  font-size: 16px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  word-break: break-all;
  max-width: 90vw;
`;

const CopiedText = styled.span`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
`;

const FloatingBlobfish = styled.img`
  position: absolute;
  width: 80px;
  height: auto;
  animation: ${floatAnimation} ${() => 5 + Math.random() * 10}s linear infinite;
  top: ${() => Math.random() * 100}%;
  left: ${() => Math.random() * 100}%;
`;

const RandomPositionedText = styled.div`
  position: absolute;
  top: ${props => props.top || 'auto'};
  left: ${props => props.left || 'auto'};
  right: ${props => props.right || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  max-width: 80vw;
  z-index: 2;
`;

const ButtonContainer = styled.div`
  @media (max-width: 600px) {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 3;
  }
`;

const RandomPositionedButton = styled.div`
  position: absolute;
  top: ${props => props.top || 'auto'};
  left: ${props => props.left || 'auto'};
  right: ${props => props.right || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  z-index: 2;

  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledButton = styled.a`
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #000000;
  background-color: #C0C0C0;
  padding: 5px 10px;
  border: 2px outset #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background-color: #D3D3D3;
  }

  &:active {
    border-style: inset;
  }
`;

export default App;